// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import api from "@helpers/api";

export default class Customer
{
	/**
	 * Set a default maximum file upload size in case the server does not provide a value
	 */
	static MAX_UPLOAD_FILE_SIZE_BYTES = 8388608; // 8MB

	/**
	 * Default form data object already contains checkboxes checked
	 */
	_formData = {
		checkbox_customer_kyc_gdpr: "yes",
		advice_sign_row_1: "yes",
		advice_sign_row_2: "yes",
		advice_sign_row_3: "yes",
		advice_sign_row_4: "yes",
		advice_sign_row_5: "yes",
		advice_sign_row_6: "yes"
	};

	_clientData = null;

	_viewStates = {};
	_parties = null;
	_workflow = null;
	_lookupInfo = null;
	_originalCaseId = null;
	_isCaseShared = false;
	_sharingDetails = null;
	_caseAdvisor = null;
	_advisorSsn = null;

	// Properties
	get clientData()
	{
		return this._clientData;
	}

	get formData()
	{
		return this._formData;
	}

	get isCompany()
	{
		if (this._formData.checkbox_company === "yes")
		{
			return true;
		}

		let identity = (this._formData.customer_social_security_number || "");
		identity = identity.replaceAll("-", "");

		if (identity.length <= 0)
		{
			return false;
		}

		if (identity.length === 12)
		{
			if (identity.substring(0, 2) === "16")
			{
				return true;
			}

			identity = identity.substring(2);
		}

		let thridDigit = parseInt(identity.substring(2, 3));
		return thridDigit >= 2;
	}

	get ssnOrg()
	{
		return this._formData.customer_social_security_number || this._formData.customer_ssn;
	}

	get companyReps()
	{
		return _getCompanyReps.call(this);
	}

	get companyRepsFair()
	{
		return _getCompanyRepsFair.call(this);
	}

	get lookupInfo()
	{
		return this._lookupInfo || null;
	}

	get caseId()
	{
		return this._caseId || null;
	}

	get documents()
	{
		return this._documents || [];
	}

	get parties()
	{
		return this._parties || [];
	}

	get workflow()
	{
		return this._workflow != null ? this._workflow : "default";
	}

	get maxUploadFileSize()
	{
		return this._maxUploadFileSize || Customer.MAX_UPLOAD_FILE_SIZE_BYTES;
	}

	get view4TableData()
	{
		return _getView4TableData.call(this);
	}

	get view5TableData()
	{
		return _getView5TableData.call(this);
	}

	get isMinor()
	{
		return !this.isCompany && (
			this.getData("caretaker_name_1", "") !== "" ||
			this.getData("caretaker_name_2", "") !== "" ||
			this.getData("caretaker_name_3", "") !== ""
		);
	}

	hasValidIdentityDocument()
	{
		// Check first if we've uploaded a new ID document that happens to be valid
		if (this.hasValidUploadedIdDocument())
		{
			return true;
		}

		// No valid document uploaded, let's check if there's one previous doc
		if (this._clientData != null &&
			this._clientData.identity_documents &&
			this._clientData.identity_documents.length > 0)
		{
			if (this.isCompany)
			{
				return true;
			}

			const lastDocumentAdded = this._clientData.identity_documents[0];

			let expirationDate = null;
			try
			{
				expirationDate = new Date(lastDocumentAdded.expiration_date);
			}
			catch (ignored) // Invalid expiration date
			{
				return false;
			}

			// Compare expiration date to today's date
			expirationDate.setHours(0, 0, 0, 0);
			const todaysDate = new Date();
			todaysDate.setHours(0, 0, 0, 0);
			return todaysDate <= expirationDate;
		}

		return false;
	}

	// Check list of documents if there's a recently uploaded id doc and if it is valid
	hasValidUploadedIdDocument()
	{
		if (!this._documents || this._documents.length === 0)
		{
			return false;
		}

		let hasIdDocument = false;
		this._documents.forEach(function (doc)
		{
			if (doc.filterKey === 'passport-id' || doc.type === 'passport-id')
			{
				hasIdDocument = true;
			}
		});

		if (this.isCompany)
		{
			return true;
		}

		if (!hasIdDocument || !this._formData["identity_doc_expiration"] || this._formData["identity_doc_expiration"].length === 0)
		{
			return false;
		}

		// Check if we've set an expiration for the document
		let expirationDate = null;
		try
		{
			expirationDate = new Date(this._formData["identity_doc_expiration"]);
		}
		catch (ignored) // Invalid expiration date
		{
			return false;
		}
		expirationDate.setHours(0, 0, 0, 0);
		const todaysDate = new Date();
		todaysDate.setHours(0, 0, 0, 0);
		return todaysDate <= expirationDate;
	}

	get identityDocumentExpiration()
	{
		if (this._formData["identity_doc_expiration"] && this._formData["identity_doc_expiration"].length > 0)
		{
			return this._formData["identity_doc_expiration"];
		}

		if (this._clientData == null || this._clientData.identity_documents.length === 0)
		{
			return null;
		}

		const lastDocumentAdded = this._clientData.identity_documents[0];
		return lastDocumentAdded.expiration_date;
	}

	get identityDocumentNumber()
	{
		if (this._clientData == null || this._clientData.identity_documents.length === 0)
		{
			if (this._formData["identity_doc_number"] && this._formData["identity_doc_number"].length > 0)
			{
				return this._formData["identity_doc_number"];
			}
			return null;
		}

		const lastDocumentAdded = this._clientData.identity_documents[0];
		return lastDocumentAdded.document_number;
	}

	get identityDocumentAdded()
	{
		if (this._clientData != null &&
			this._clientData.identity_documents &&
			this._clientData.identity_documents.length > 0)
		{
			const lastDocumentAdded = this._clientData.identity_documents[0];
			return lastDocumentAdded.added;
		}

		return null;
	}

	resetCustomer()
	{
		console.log("Resetting customer data");
		this._formData = {};
		this._viewStates = {};
		this._caseId = null;
		this._documents = null;
		this._parties = null;
		this._lookupInfo = null;
		this._clientData = null;
		this._originalCaseId = null;
		this._isCaseShared = false;
		this._sharingDetails = null;
		this._caseAdvisor = null;
		this._advisorSsn = null;
	}

	// Methods
	getData(key, defaultValue = "")
	{
		let value = this._formData[key];

		if (typeof value !== "undefined" && value !== null)
		{

			if (key === 'customer_social_security_number' && value.startsWith("16"))
			{
				// Remove 16 from start of company registration number
				return value.substring(2);
			}

			return value;
		}

		return defaultValue;
	}

	updateFormData(formData)
	{
		this._formData = {...this._formData, ...formData};
	}

	addDocument(doc)
	{
		let documents = [...this.documents];

		// Remove any existing 'passport-id' type documents (Max 1 ID document)
		if (doc.filterKey.startsWith("passport-id"))
		{
			documents = documents.filter(d =>
			{
				if (d.type)
				{
					return d.type !== doc.filterKey
				}

				if (d.filterKey)
				{
					return d.filterKey !== doc.filterKey
				}

				return true;
			});
		}

		documents.push(doc);
		this._documents = documents;
	}

	removeDocumentAtIndex(index)
	{
		const documents = [...this.documents];
		documents.splice(index, 1);
		this._documents = documents;
	}

	removeDocumentWithName(name, type = null)
	{
		const {caseId} = this;
		const documents = [...this.documents];
		for (let i = documents.length - 1; i >= 0; i--)
		{
			const d = documents[i];
			if (d.name === name || d.document_name === name)
			{
				documents.splice(i, 1);

				if (d.document_name || d.uploaded)
				{
					// also remove from db
					api("/case/document/delete", {caseId, docName: d.name || d.document_name, type});
				}
				break;
			}
		}
		this._documents = documents;
	}

	clearCaseDataAfterRestoringFromSigned()
	{
		console.log("Clearing form values that should not be retained from old case (uploaded files)");
		this._documents = null;

		// Reset fields for creation of new account, this should not be kept when creating a case from signed document
		this._formData['date'] = "";
		this._formData['customer_account_broker_2'] = "";
		this._formData['customer_account_type_2'] = "";
		this._formData['radio_account_type_open'] = "";
		this._formData['account_account_fee'] = "";
		this._formData['account_advice_fee'] = "";
		this._formData['account_performance_fee'] = "";
		this._formData['account_performance_hurdle_fee'] = "";
		this._formData['radio_reseller_poa'] = "";
		this._formData['customer_autogiro'] = "";
		this._formData['radio_product_class_a'] = "";
		this._formData['radio_product_class_b'] = "";
		this._formData['advice_include_front_page'] = "";

		/**
		 * Clear Citroneer account opening fields when you create a new case
		 */
		this._formData['account_selection_recommendation'] = "";
		this._formData['checkbox_advice_onboarding'] = "";
		this._formData['chk_accounttype_naked'] = "";
		this._formData['chk_accounttype_isk'] = "";
		this._formData['chk_accounttype_kf'] = "";
		this._formData['chk_accounttype_tp'] = "";
		this._formData['account_no_depa'] = "";
		this._formData['account_no_isk'] = "";
		this._formData['account_no_kf'] = "";
		this._formData['account_no_tp'] = "";
		this._formData['identity_doc_expiration'] = "";
		this._formData['identity_doc_number'] = "";
		this._formData['uppdragsavtal_af_task_1'] = "";
		this._formData['uppdragsavtal_af_fee_1'] = "";
		this._formData['uppdragsavtal_af_task_2'] = "";
		this._formData['uppdragsavtal_af_fee_2'] = "";
		this._formData['uppdragsavtal_af_task_3'] = "";
		this._formData['uppdragsavtal_af_fee_3'] = "";
		this._formData['uppdragsavtal_isk_task_1'] = "";
		this._formData['uppdragsavtal_isk_fee_1'] = "";
		this._formData['uppdragsavtal_isk_task_2'] = "";
		this._formData['uppdragsavtal_isk_fee_2'] = "";
		this._formData['uppdragsavtal_isk_task_3'] = "";
		this._formData['uppdragsavtal_isk_fee_3'] = "";
		this._formData['uppdragsavtal_kf_task_1'] = "";
		this._formData['uppdragsavtal_kf_fee_1'] = "";
		this._formData['uppdragsavtal_kf_task_2'] = "";
		this._formData['uppdragsavtal_kf_fee_2'] = "";
		this._formData['uppdragsavtal_kf_task_3'] = "";
		this._formData['uppdragsavtal_kf_fee_3'] = "";
		this._formData['uppdragsavtal_tp_task_1'] = "";
		this._formData['uppdragsavtal_tp_fee_1'] = "";
		this._formData['uppdragsavtal_tp_task_2'] = "";
		this._formData['uppdragsavtal_tp_fee_2'] = "";
		this._formData['uppdragsavtal_tp_task_3'] = "";
		this._formData['uppdragsavtal_tp_fee_3'] = "";
		this._formData['send_welcome_email'] = "";
		this._formData['recommendation_for_account'] = "";
	}

	uploadDocuments()
	{
		const {caseId, documents, formData} = this;

		if (!caseId)
		{
			throw new Error("Could not upload document(s). No case ID exists.");
		}

		documents.forEach(doc =>
		{
			if ((doc.filterKey === "passport-id-0" ||
				doc.filterKey === "passport-id-1" ||
				doc.filterKey === "passport-id-2" ||
				doc.filterKey === "passport-id-3" ||
				doc.filterKey === "passport-id-4" ||
				doc.filterKey === "passport-id-5"))
			{
				doc.expiration = formData['identity_doc_expiration_' + doc.index]
				doc.number = formData['identity_doc_number_' + doc.index];
				doc.filterKey = "passport-id";
			}
			else if (doc.filterKey === "passport-id")
			{
				doc.expiration = formData['identity_doc_expiration'] ? formData['identity_doc_expiration'] : null;
				doc.number = formData['identity_doc_number'] ? formData['identity_doc_number'] : null;
			}
			else
			{
				doc.expiration = null;
				doc.number = null;
			}
		});

		return api("/case/upload-documents", {caseId, documents});
	}

	lookupAsync()
	{
		return new Promise(async (resolve, reject) =>
		{
			if (!this._lookupInfo)
			{
				const lookupValue = this.ssnOrg;
				const lookupKey = this.isCompany || lookupValue.substring(0, 2) === "16" ? "orgNumber" : "ssn";
				try
				{
					// If not, do a regular lookup
					const lookupResult = await api("/lookup", {[lookupKey]: lookupValue});

					this._lookupInfo = Object.freeze(lookupResult);
					this._clientData = this._lookupInfo.client;
					resolve({source: "lookup"});
				}
				catch (e)
				{
					console.error(e.message);
					reject(e);
				}
			}
		});
	}

	async loadDataFromCase(caseId, actualUserSsn, advisorSsn)
	{
		let {
			client,
			lookup,
			formData,
			documents,
			parties,
			maxUploadFileSize,
			isDraft,
			isRecalled,
			isShared,
			sharing,
			workflow,
			newAuthToken,
			advisor
		} = await api("/case/get", {
			caseId,
			userId: actualUserSsn,
			partnerId: advisorSsn
		});

		formData['radio_customer_autogiro'] = "no";
		if (formData['customer_autogiro_bank'] && formData['customer_autogiro_bank'].length > 0)
		{
			formData['radio_customer_autogiro'] = "yes";
		}

		this._caseId = caseId;
		this._formData = formData;
		this._documents = documents;
		this._maxUploadFileSize = maxUploadFileSize;
		this._parties = parties;
		this._workflow = workflow;
		this._clientData = client;
		this._isCaseShared = isShared;
		this._sharingDetails = sharing;
		this._advisorSsn = advisorSsn;
		this._caseAdvisor = advisor;

		if (this._lookupInfo === null)
		{
			this._lookupInfo = lookup;
			this._lookupInfo.client = client;
		}

		console.log("Auth token refreshed.");
		Settings.set("AUTH_TOKEN", newAuthToken);

		return {isDraft, isRecalled, workflow};
	}

	getSignatoriesData()
	{
		const result = [];

		if (this.isCompany)
		{
			const getFieldName = (name, index) => `company_representative_${name}_${index}`;

			for (let i = 0; i < 6; i++)
			{
				const firstName = this.getData(getFieldName("firstname", i));
				const lastName = this.getData(getFieldName("lastname", i));
				const ssn = this.getData(getFieldName("sec_num", i)) || this.getData(getFieldName("ssn", i));
				const email = this.getData(getFieldName("email", i));
				const isSignatory = this.getData(`checkbox_signatory_${i}`) === "yes";

				if (!!firstName && isSignatory)
				{
					result.push(Object.freeze({
						firstName,
						lastName,
						ssn,
						email,
						phoneNumber: "",
						isSmsRequested: false
					}));
				}
			}

			/**
			 * Signatories were empty - get from alternative fields
			 */
			if (result.length === 0)
			{
				const getFieldNameSignatory = (name, index) => `company_signatory_${name}_${index}`;

				for (let i = 0; i < 4; i++)
				{
					const firstName = this.getData(getFieldNameSignatory("firstname", i));
					const lastName = this.getData(getFieldNameSignatory("lastname", i));
					const ssn = this.getData(getFieldNameSignatory("sec_num", i));
					const email = this.getData(getFieldNameSignatory("email", i));

					if (!!firstName)
					{
						result.push(Object.freeze({
							firstName,
							lastName,
							ssn,
							email,
							phoneNumber: "",
							isSmsRequested: false
						}));
					}
				}
			}

			if (result.length === 0)
			{
				const firstName = this.getData("customer_representative_firstname") || "";
				const lastName = this.getData("customer_representative_lastname") || "";
				const ssn = this.getData("customer_representative_ssn") || "";
				const email = this.getData("customer_email") || "";

				if (!!firstName)
				{
					result.push(Object.freeze({
						firstName,
						lastName,
						ssn,
						email,
						phoneNumber: "",
						isSmsRequested: false
					}));
				}
			}
		}
		else
		{
			const guardian1name = this.getData("caretaker_name_1") || "";
			const guardian2name = this.getData("caretaker_name_2") || "";
			const guardian3name = this.getData("caretaker_name_3") || "";

			const ssn = this.getData("customer_social_security_number") || this.getData("customer_ssn");
			const email = this.getData("customer_email");
			let phoneNumber = (this.getData("customer_phonenumber_cell") && this.getData("customer_phonenumber_cell") !== "-")
				? this.getData("customer_phonenumber_cell") : "";

			// Use the home number if it starts with +467 (a Swedish cell phone number)
			if (phoneNumber.length === 0 && this.getData("customer_phonenumber_home"))
			{
				const homeNumber = this.getData("customer_phonenumber_home");
				if (homeNumber.length > 4 && homeNumber.substring(0, 4) === "+467")
				{
					phoneNumber = homeNumber;
				}
			}

			if (guardian1name.length > 0 || guardian2name.length > 0 || guardian3name.length > 0)
			{
				console.log("Minor :: Setting guardians as signatories");
				if (guardian1name.length > 0)
				{
					const g1firstName = guardian1name.indexOf(" ") > 0 ? guardian1name.substring(0, guardian1name.indexOf(" ")).trim() : "-";
					const g1LasttName = guardian1name.indexOf(" ") > 0 ? guardian1name.substring(guardian1name.indexOf(" ")).trim() : guardian1name;
					const g1Ssn = this.getData("caretaker_ssn_1");
					result.push(Object.freeze({
						firstName: g1firstName,
						lastName: g1LasttName,
						ssn: g1Ssn,
						email,
						phoneNumber,
						isSmsRequested: phoneNumber.length > 0
					}));
				}
				else
				{
					const g2firstName = guardian2name.indexOf(" ") > 0 ? guardian2name.substring(0, guardian2name.indexOf(" ")).trim() : "-";
					const g2LasttName = guardian2name.indexOf(" ") > 0 ? guardian2name.substring(guardian2name.indexOf(" ")).trim() : guardian2name;
					const g2ssn = this.getData("caretaker_ssn_2");
					result.push(Object.freeze({
						firstName: g2firstName,
						lastName: g2LasttName,
						ssn: g2ssn,
						email,
						phoneNumber,
						isSmsRequested: phoneNumber.length > 0
					}));

					const g3firstName = guardian3name.indexOf(" ") > 0 ? guardian3name.substring(0, guardian3name.indexOf(" ")).trim() : "-";
					const g3LasttName = guardian3name.indexOf(" ") > 0 ? guardian3name.substring(guardian3name.indexOf(" ")).trim() : guardian3name;
					const g3ssn = this.getData("caretaker_ssn_3");
					result.push(Object.freeze({
						firstName: g3firstName,
						lastName: g3LasttName,
						ssn: g3ssn,
						email,
						phoneNumber,
						isSmsRequested: phoneNumber.length > 0
					}));
				}

			}
			else
			{
				const firstName = this.getData("customer_firstname");
				const lastName = this.getData("customer_lastname");
				result.push(Object.freeze({
					firstName,
					lastName,
					ssn,
					email,
					phoneNumber,
					isSmsRequested: phoneNumber.length > 0
				}));
			}
		}
		return result;
	}
}

// PRIVATE FUNCTIONS
function _getCompanyReps()
{
	const {formData} = this;
	const repsCount = 6;

	let checkboxNames = {
		signatory: "checkbox_signatory_#",
		ubo: "checkbox_ubo_#",
		poa: "checkbox_poa_#",
		view: "checkbox_view_#",
		mng: "checkbox_mng_#",
	};

	let fieldNames = {
		firstName: "company_representative_firstname_#",
		lastName: "company_representative_lastname_#",
		email: "company_representative_email_#",
		phone: "company_representative_phone_#",
		ssn: "company_representative_sec_num_#",
		citizenship: "company_representative_citizenship_#",
		tax_country: "company_representative_tax_country_#",
		ownership: "company_representative_percentage_#",
		hasDocument: "company_representative_hasdoc_#",
	};

	/**
	 * Check for an alternative form field format
	 */
	if (formData["customer_comprepr_firstname_0"])
	{
		checkboxNames = {
			signatory: "checkbox_customer_comprepr_signatory_#",
			benowner: "checkbox_customer_comprepr_benowner_#",
			poa: "checkbox_customer_comprepr_poa_#",
			view: "checkbox_customer_comprepr_view_#",
			board: "checkbox_customer_comprepr_board_#",
		};

		fieldNames = {
			firstName: "customer_comprepr_firstname_#",
			lastName: "customer_comprepr_lastname_#",
			email: "customer_comprepr_email_#",
			phone: "customer_comprepr_phone_#",
			ssn: "customer_comprepr_sec_num_#",
			citizenship: "customer_comprepr_citizenship_#",
			tax_country: "customer_comprepr_tax_country_#",
			ownership: "customer_comprepr_percentage_#",
			hasDocument: "customer_comprepr_hasdoc_#",
		};
	}

	const result = [];
	for (let i = 0; i < repsCount; i++)
	{
		const repData = {types: []};

		// Define rep types
		for (const typeName in checkboxNames)
		{
			const name = checkboxNames[typeName].replace("#", i.toString());
			if (formData[name] === "yes")
			{
				repData.types.push(typeName);
			}
		}

		// Define rest of rep data
		for (const key in fieldNames)
		{
			const name = fieldNames[key].replace("#", i.toString());
			if (formData[name])
			{
				repData[key] = formData[name];
			}
			else
			{
				repData[key] = "";
			}
		}

		result.push(repData);
	}

	return result;
}

// PRIVATE FUNCTIONS
function _getCompanyRepsFair()
{
	const {formData} = this;
	const repsCount = 8;

	const fieldNames = {
		firstName: "company_benowner_firstname_#",
		lastName: "company_benowner_lastname_#",
		ssn: "company_benowner_ssn_#",
		ownership: "company_benowner_percentage_#",
		voting: "company_benowner_votepercentage_#"
	};

	const result = [];
	for (let i = 0; i < repsCount; i++)
	{
		const repData = {};

		// Define rest of rep data
		for (const key in fieldNames)
		{
			const name = fieldNames[key].replace("#", i.toString());
			if (formData[name])
			{
				repData[key] = formData[name];
			}
			else
			{
				repData[key] = "";
			}
		}

		repData.isCompany = repData.firstName !== "" && repData.lastName === "";

		result.push(repData);
	}

	return result;
}

function _getView4TableData()
{
	return {
		portfolio_buffer_1: this.getData("savingsprofile_portfolio_buffer_1"),
		portfolio_buffer_2: this.getData("savingsprofile_portfolio_buffer_2"),
		portfolio_buffer_3: this.getData("savingsprofile_portfolio_buffer_3"),
		portfolio_buffer_4: this.getData("savingsprofile_portfolio_buffer_4"),
		portfolio_buffer_5: this.getData("savingsprofile_portfolio_buffer_5"),
		portfolio_buffer_6: this.getData("savingsprofile_portfolio_buffer_6"),
		portfolio_buffer_7: this.getData("savingsprofile_portfolio_buffer_7"),
		portfolio_buffer_sum: this.getData("savingsprofile_portfolio_buffer_sum"),
		portfolio_buffer_sum_risk: this.getData("savingsprofile_portfolio_buffer_sum_risk"),

		portfolio_1_3_1: this.getData("savingsprofile_portfolio_1_3_1"),
		portfolio_1_3_2: this.getData("savingsprofile_portfolio_1_3_2"),
		portfolio_1_3_3: this.getData("savingsprofile_portfolio_1_3_3"),
		portfolio_1_3_4: this.getData("savingsprofile_portfolio_1_3_4"),
		portfolio_1_3_5: this.getData("savingsprofile_portfolio_1_3_5"),
		portfolio_1_3_6: this.getData("savingsprofile_portfolio_1_3_6"),
		portfolio_1_3_7: this.getData("savingsprofile_portfolio_1_3_7"),
		portfolio_1_3_sum: this.getData("savingsprofile_portfolio_1_3_sum"),
		portfolio_1_3_sum_risk: this.getData("savingsprofile_portfolio_1_3_sum_risk"),

		portfolio_3_5_1: this.getData("savingsprofile_portfolio_3_5_1"),
		portfolio_3_5_2: this.getData("savingsprofile_portfolio_3_5_2"),
		portfolio_3_5_3: this.getData("savingsprofile_portfolio_3_5_3"),
		portfolio_3_5_4: this.getData("savingsprofile_portfolio_3_5_4"),
		portfolio_3_5_5: this.getData("savingsprofile_portfolio_3_5_5"),
		portfolio_3_5_6: this.getData("savingsprofile_portfolio_3_5_6"),
		portfolio_3_5_7: this.getData("savingsprofile_portfolio_3_5_7"),
		portfolio_3_5_sum: this.getData("savingsprofile_portfolio_3_5_sum"),
		portfolio_3_5_sum_risk: this.getData("savingsprofile_portfolio_3_5_sum_risk"),

		portfolio_longterm_1: this.getData("savingsprofile_portfolio_longterm_1"),
		portfolio_longterm_2: this.getData("savingsprofile_portfolio_longterm_2"),
		portfolio_longterm_3: this.getData("savingsprofile_portfolio_longterm_3"),
		portfolio_longterm_4: this.getData("savingsprofile_portfolio_longterm_4"),
		portfolio_longterm_5: this.getData("savingsprofile_portfolio_longterm_5"),
		portfolio_longterm_6: this.getData("savingsprofile_portfolio_longterm_6"),
		portfolio_longterm_7: this.getData("savingsprofile_portfolio_longterm_7"),
		portfolio_longterm_sum: this.getData("savingsprofile_portfolio_longterm_sum"),
		portfolio_longterm_sum_risk: this.getData("savingsprofile_portfolio_longterm_sum_risk"),

		portfolio_tot_sum: this.getData("savingsprofile_portfolio_tot_sum"),
		portfolio_tot_sum_risk: this.getData("savingsprofile_portfolio_tot_sum_risk"),

		customer_buffer_under_1_year: this.getData("customer_savingsprofile_buffer_under_1_year"),
		customer_1_3_year: this.getData("customer_savingsprofile_1_3_year"),
		customer_3_5_year: this.getData("customer_savingsprofile_3_5_year"),
		customer_longterm_over_5_year: this.getData("customer_savingsprofile_longterm_over_5_year"),
		customer_total_savings: this.getData("customer_savingsprofile_total_savings"),
		customer_sum: this.getData("customer_savingsprofile_sum"),
		customer_sum_monthly_net: this.getData("customer_sum_monthly_net")
	};
}

function _getView5TableData()
{
	return {
		kyc_experience_aktier_investments: this.getData("kyc_experience_aktier_investments"),
		kyc_experience_fonder_investments: this.getData("kyc_experience_fonder_investments"),
		kyc_experience_obligationer_investments: this.getData("kyc_experience_obligationer_investments"),
		kyc_experience_aio_investments: this.getData("kyc_experience_aio_investments"),
		kyc_experience_sprinter_investments: this.getData("kyc_experience_sprinter_investments"),
		kyc_experience_kreditcertifikat_investments: this.getData("kyc_experience_kreditcertifikat_investments"),
		kyc_experience_autocalls_investments: this.getData("kyc_experience_autocalls_investments"),
		kyc_experience_derivat_investments: this.getData("kyc_experience_derivat_investments"),
		kyc_experience_deltagandeinstrument_investments: this.getData("kyc_experience_deltagandeinstrument_investments"),

		radio_kyc_knowledge_aktier: this.getData("radio_kyc_knowledge_aktier", "ingen"),
		radio_kyc_knowledge_onot_aktier: this.getData("radio_kyc_knowledge_onot_aktier", "ingen"),
		radio_kyc_knowledge_fonder: this.getData("radio_kyc_knowledge_fonder", "ingen"),
		radio_kyc_knowledge_obligationer: this.getData("radio_kyc_knowledge_obligationer", "ingen"),
		radio_kyc_knowledge_aio: this.getData("radio_kyc_knowledge_aio", "ingen"),
		radio_kyc_knowledge_sprinter: this.getData("radio_kyc_knowledge_sprinter", "ingen"),
		radio_kyc_knowledge_kreditcertifikat: this.getData("radio_kyc_knowledge_kreditcertifikat", "ingen"),
		radio_kyc_knowledge_autocalls: this.getData("radio_kyc_knowledge_autocalls", "ingen"),
		radio_kyc_knowledge_derivat: this.getData("radio_kyc_knowledge_derivat", "ingen"),
		radio_kyc_knowledge_deltagandeinstrument: this.getData("radio_kyc_knowledge_deltagandeinstrument", "ingen"),
		radio_kyc_knowledge_depaisk: this.getData("radio_kyc_knowledge_depaisk", "ingen"),
		radio_kyc_knowledge_radgivning: this.getData("radio_kyc_knowledge_radgivning", "ingen"),
		radio_kyc_knowledge_index_products: this.getData("radio_kyc_knowledge_index_products", "ingen"),

		radio_kyc_knowledge_childins: this.getData("radio_kyc_knowledge_childins"),
		radio_kyc_knowledge_healthins: this.getData("radio_kyc_knowledge_healthins"),
		radio_kyc_knowledge_lifeins: this.getData("radio_kyc_knowledge_lifeins"),
		radio_kyc_knowledge_accins: this.getData("radio_kyc_knowledge_accins"),
		radio_kyc_knowledge_tins: this.getData("radio_kyc_knowledge_tins"),
		radio_kyc_knowledge_pins: this.getData("radio_kyc_knowledge_pins"),
		radio_kyc_knowledge_kins: this.getData("radio_kyc_knowledge_kins"),

		radio_understands_aktier: this.getData("radio_understands_aktier"),
		radio_understands_onot_aktier: this.getData("radio_understands_onot_aktier"),
		radio_understands_fonder: this.getData("radio_understands_fonder"),
		radio_understands_aio: this.getData("radio_understands_aio"),
		radio_understands_autocalls: this.getData("radio_understands_autocalls"),
		radio_understands_derivat: this.getData("radio_understands_derivat"),
		radio_understands_depaisk: this.getData("radio_understands_depaisk"),
		radio_understands_radgivning: this.getData("radio_understands_radgivning"),
		radio_understands_index_products: this.getData("radio_understands_index_products"),

		radio_understands_depa: this.getData("radio_understands_depa", ""),
		radio_understands_isk: this.getData("radio_understands_isk", ""),
		radio_understands_tpk: this.getData("radio_understands_tpk", ""),
		radio_understands_investment: this.getData("radio_understands_investment", ""),

		radio_experience_depa: this.getData("radio_experience_depa", ""),
		radio_experience_isk: this.getData("radio_experience_isk", ""),
		radio_experience_tpk: this.getData("radio_experience_tpk", ""),
		radio_experience_investment: this.getData("radio_experience_investment", ""),

		radio_education_depa: this.getData("radio_education_depa", ""),
		radio_education_isk: this.getData("radio_education_isk", ""),
		radio_education_tpk: this.getData("radio_education_tpk", ""),
		radio_education_investment: this.getData("radio_education_investment", ""),

		radio_kyc_education_aktier: this.getData("radio_kyc_education_aktier"),
		radio_kyc_education_onot_aktier: this.getData("radio_kyc_education_onot_aktier"),
		radio_kyc_education_fonder: this.getData("radio_kyc_education_fonder"),
		radio_kyc_education_obligationer: this.getData("radio_kyc_education_obligationer"),
		radio_kyc_education_aio: this.getData("radio_kyc_education_aio"),
		radio_kyc_education_sprinter: this.getData("radio_kyc_education_sprinter"),
		radio_kyc_education_kreditcertifikat: this.getData("radio_kyc_education_kreditcertifikat"),
		radio_kyc_education_autocalls: this.getData("radio_kyc_education_autocalls"),
		radio_kyc_education_derivat: this.getData("radio_kyc_education_derivat"),
		radio_kyc_education_deltagandeinstrument: this.getData("radio_kyc_education_deltagandeinstrument"),

		radio_kyc_broker_risk_aktier: this.getData("radio_kyc_broker_risk_aktier"),
		radio_kyc_broker_risk_onot_aktier: this.getData("radio_kyc_broker_risk_onot_aktier"),
		radio_kyc_broker_risk_fonder: this.getData("radio_kyc_broker_risk_fonder"),
		radio_kyc_broker_risk_obligationer: this.getData("radio_kyc_broker_risk_obligationer"),
		radio_kyc_broker_risk_aio: this.getData("radio_kyc_broker_risk_aio"),
		radio_kyc_broker_risk_sprinter: this.getData("radio_kyc_broker_risk_sprinter"),
		radio_kyc_broker_risk_kreditcertifikat: this.getData("radio_kyc_broker_risk_kreditcertifikat"),
		radio_kyc_broker_risk_autocalls: this.getData("radio_kyc_broker_risk_autocalls"),
		radio_kyc_broker_risk_derivat: this.getData("radio_kyc_broker_risk_derivat"),
		radio_kyc_broker_risk_deltagandeinstrument: this.getData("radio_kyc_broker_risk_deltagandeinstrument"),

		radio_kyc_transactions_aktier: this.getData("radio_kyc_transactions_aktier"),
		radio_kyc_transactions_onot_aktier: this.getData("radio_kyc_transactions_onot_aktier"),
		radio_kyc_transactions_fonder: this.getData("radio_kyc_transactions_fonder"),
		radio_kyc_transactions_aio: this.getData("radio_kyc_transactions_aio"),
		radio_kyc_transactions_autocalls: this.getData("radio_kyc_transactions_autocalls"),
		radio_kyc_transactions_deltaginstr: this.getData("radio_kyc_transactions_deltaginstr"),
		radio_kyc_transactions_depaisk: this.getData("radio_kyc_transactions_depaisk"),
		radio_kyc_transactions_radgivning: this.getData("radio_kyc_transactions_radgivning"),
		radio_kyc_transactions_index_products: this.getData("radio_kyc_transactions_index_products"),
		radio_kyc_transactions_derivat: this.getData("radio_kyc_transactions_derivat"),

		radio_aml_transaction_size_aktier: this.getData("radio_aml_transaction_size_aktier"),
		radio_aml_transaction_size_onot_aktier: this.getData("radio_aml_transaction_size_onot_aktier"),
		radio_aml_transaction_size_fonder: this.getData("radio_aml_transaction_size_fonder"),
		radio_aml_transaction_size_aio: this.getData("radio_aml_transaction_size_aio"),
		radio_aml_transaction_size_autocalls: this.getData("radio_aml_transaction_size_autocalls"),
		radio_aml_transaction_size__deltaginstr: this.getData("radio_aml_transaction_size__deltaginstr"),
		radio_aml_transaction_size_depaisk: this.getData("radio_aml_transaction_size_depaisk"),
		radio_aml_transaction_size_radgivning: this.getData("radio_aml_transaction_size_radgivning"),
		radio_aml_transaction_size_index_products: this.getData("radio_aml_transaction_size_index_products"),
		radio_aml_transaction_size_derivat: this.getData("radio_aml_transaction_size_derivat")
	};
}